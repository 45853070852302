<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :labelPosition="labelPosition"
      :rowSize="4"
      :optionsBtn="true"
      :labelWidth="labelWidth"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      url="apinew/holidayPrice/getListExport"
      fileName="节日工价设置导出"
      :exportData="exportData"
    >
      <EButton @click="dialogVisible1=true">
        添加
      </EButton>
    </EForm>
    <ETable :tableTitle="tableTitle"
            :tableData="tableData"
            :needPagination="true"
            :count="count"
            @changePage="changePage"
            @changeSize="changeSize"
            :page="searchForm.page"
            :page_size="searchForm.page_size"
    >
      <el-table-column
        fixed="right"
        label="状态"
        width="250px"
      >
        <template slot-scope="scope">
          <div>
            <EButton type="text" @click="change(scope.row.price_uuid,'1')" :disabled="scope.row.status == 1">
              启用
            </EButton>
            <EButton type="text" @click="change(scope.row.price_uuid,'0')" :disabled="scope.row.status == 0">
              禁用
            </EButton>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="180px"
      >
        <template slot-scope="scope">
          <div>
            <EButton type="text" @click="showDetail(scope.row.price_uuid,)">
              查看
            </EButton>
            <EButton type="text" @click="editPriceSet(scope.row)">
              编辑
            </EButton>
          </div>
        </template>
      </el-table-column>
    </ETable>

    <!--    添加节日设置弹窗-->
    <EDialog width='35%' :dialogVisible="dialogVisible1" title="添加" @handleClose="handleClose">
      <el-form class="demo-form-inline" label-width="140px" label-position="right"
               :model="searchForm2"
               ref="form2" size="medium">
        <el-form-item label="节日名称" prop="user_mobile">
          <el-input v-model="searchForm2.name" prop="user_mobile" class="w300" placeholder="最多允许录入10个字"
                    maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="节日周期" prop="time">
          <el-date-picker v-model="searchForm2.time" type="daterange" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" class="w300">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="区域" prop="user_mobile">
          <el-cascader placeholder="请输入" :options="cityOptions" :props="{ checkStrictly: true }" clearable filterable
                       v-model="areaCodeAll" class="w300" @change="getCityFn"
                       ref="myCascader"></el-cascader>
        </el-form-item>
        <el-form-item label="岗位/工种">
          <el-cascader placeholder="请输入" :options="professionOptions" :props="{checkStrictly: false }" clearable filterable
                       class="w300" v-model="profession_id"></el-cascader>
        </el-form-item>
        <el-form-item label="等级" prop="profession_id">
          <div class="tag-list">
            <div class="tag"
                 v-for="(item,index) in levelSet" @click="getbrokerageData(item.label)"
                 :key="index">
              {{item.label}}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="searchForm2.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="onSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </EDialog>

    <!--抽成方式配置弹窗-->
    <EDialog width='35%' :dialogVisible="dialogVisible2" title="抽成方式配置" @handleClose="handleClose2">
      <el-form label-width="250px" label-position="right" size="medium">
        <el-form-item label="抽成方式" required>
          <el-radio-group>
            <el-radio label="const_price_difference">固定单价-固定差额
            </el-radio>
            <el-radio label="const_price_brokerage">固定单价-比例抽成
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div>
          <el-form-item label="雇主固定发布价格（元/小时）" required>
            <el-input style="width:200px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="平台差额（元/小时）" required>
            <el-input style="width:200px;"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="雇主固定发布价格（元/小时）" required>
            <el-input style="width:200px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="抽成（%）" required>
            <el-input style="width:200px;"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="danger" @click="deleteBrokerageData">清 除</el-button>
          <el-button type="primary" @click="saveBrokerageData">保 存</el-button>
        </el-form-item>
      </el-form>
    </EDialog>

    <!--查看节日价格详情-->
    <!--    <EDialog width='35%' :dialogVisible="dialogVisible3" title="抽成方式配置" @handleClose="handleClose2">-->
    <!--      <el-form label-width="250px" label-position="right" size="medium">-->
    <!--        <el-form-item>-->
    <!--          -->
    <!--        </el-form-item>-->
    <!--      </el-form>-->
    <!--    </EDialog>-->
    <EDialog width='35%' :dialogVisible="dialogVisible3" title="抽成方式配置" @handleClose="handleClose3">
      <div v-for="(item,index) in detailList">
        <div>{{item.result}}</div>
        <el-divider></el-divider>
        <infoList :list="item.pricing_type_str=='固定单价-固定差额'?baseList1:baseList2"
                  :result="item.pricing_type_str=='固定单价-固定差额'?result1:result2"></infoList>
      </div>
    </EDialog>

  </div>
</template>
<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'

  export default {
    name: "holidayWageSet",
    data() {
      return {
        formColumns: [
          {
            title: '节日名称',
            type: 'text',
            property: 'name',
            placeHolder: '',
            show: true
          },
          {
            title: '节日日期',
            type: 'datePicker',
            property: 'date',
            placeholder: '',
            show: true
          },
          {
            title: '区域',
            type: 'areaLevelCascader',
            property: 'area_code',
            placeholder: '最多可录入20字',
            show: true,
            checkStrictly: true,
            options: []
          },
          {
            title: '岗位/工种',
            type: 'professionCascader',
            property: 'profession_id',
            placeholder: '最多可录入20字',
            show: true,
            checkStrictly: true,
            options: []
          },
        ],
        tableTitle: [
          {
            label: '节日ID',
            prop: 'price_id'
          },
          {
            label: '节日名称',
            prop: 'name'
          },
          {
            label: '节日周期',
            prop: 'time_period'
          },
          {
            label: '区域',
            prop: 'area_name'
          },
          {
            label: '岗位/工种',
            prop: 'profession_name'
          },
          {
            label: '状态',
            prop: 'status'
          },
        ],
        tableData: [],
        searchForm: {
          name: '',
          date: '',
          area_code: '',
          profession_id: '',
          page: 1,
          page_size: 10
        },
        searchForm2: { //工价设置的弹窗form
          name: '',
          start_date: '',
          end_date: '',
          area_code: '',
          profession_id: '',
          price_data: [],//不同等级的价格配置数据
          status: 0,//
        },
        time: [],
        price_data: {//每一个小的borderage_data对象组成dialogForm的大的borderage_data数组
          price_type: '',
        },
        count: null,
        labelPosition: 'top',
        labelWidth: '100px',
        labelPosition2: 'right',
        labelWidth2: '120px',
        exportData: {},
        dialogVisible1: false,//添加工价设置弹窗
        dialogVisible2: false,//抽成方式配置
        dialogVisible3: false,//查看详情弹窗
        cityOptions: [],
        professionOptions: [],
        areaCodeAll: [],
        profession_id: [],
        levelSet: [
          {
            label: '初级',
            value: 1,
          },
          {
            label: '中级',
            value: 2,
          }, {
            label: '高级',
            value: 3,
          }
          , {
            label: '专属级',
            value: 4,
          }
        ],
        baseList1: [
          {
            label: '抽成方式',
            property: 'pricing_type_str',
          },
          {
            label: '雇主固定发布价格（元/小时）',
            property: 'normal_price',
          },
          {
            label: '平台差额（元/小时',
            property: 'platform_brokerage',

          },
        ],
        baseList2: [
          {
            label: '抽成方式',
            property: 'pricing_type_str',
          },
          {
            label: '雇主固定发布价格（元/小时）',
            property: 'normal_price',
          },
          {
            label: '抽成（%）',
            property: 'brokerage',

          },
        ],
        result1: {
          pricing_type_str: '',
          normal_price: '',
          platform_brokerage: ''
        },
        result2: {
          pricing_type_str: '',
          normal_price: '',
          brokerage: ''
        },
        detailList: [],
      }
    },
    components: {ETable, EDialog, EButton, EForm},
    created() {
      this.getData()
      this.gainContent()
      this.getAreaLevelOptions()
    },
    methods: {
      /*获取岗位数据*/
      async gainContent() {
        let res = await Http.getProfessionList()
        if (res.flag === '1') {
          this.professionOptions = res.data
        }
      },
      // getCascaderChange(num,property) {
      //   console.log(num)
      //   console.log(property)
      //   this.areaCodeAll = num
      //   if (num.length != 0) {
      //     this.searchForm.area_code = num[num.length - 1]
      //   } else {
      //     this.searchForm.area_code = ''
      //   }
      // },
      getCityFn(num) {
        console.log(num)
        this.areaCodeAll = num
        if (num.length != 0) {
          this.form.area_code = num[num.length - 1]
        } else {
          this.form.area_code = ''
        }
      },
      //获取省和市，admin不收权限限制
      async getAreaLevelOptions() {
        let res = await Http.commonAreaOptionList({
          type: 1,
          area_level_auth: 2
        })
        if (res.flag == '1') {
          console.log(res)
          this.cityOptions = res.data

        } else {
        }
      },
      changePage(page) {
        this.searchForm.page = page
        this.getData()
      },
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      onSearch() {
        console.log(this.searchForm.address)
        this.searchForm.page = 1
        this.getData()
      },
      async getData() {
        let {name, date, area_code, profession_id, page, page_size} = this.searchForm
        this.exportData = {
          name, date, area_code: area_code ? area_code[area_code.length - 1] : '',profession_id: profession_id ? profession_id[profession_id.length - 1] : '', page, page_size
        }
        let res = await Http.getHolidayPriceList(this.exportData)
        this.tableData = res.data.list
        this.count = Number(res.data.total)
      },
      handleClose() {
        this.dialogVisible1 = false
      },
      onSubmit() {
      },
      showDetail(price_uuid) {
        this.dialogVisible3 = true
        this.getHolidayPriceDetail(price_uuid)
      },
      // 列表数据启用和禁用change方法
      change(price_uuid, status) {
        let msg = ''
        if (status == 1) {
          msg = '启用'
        } else {
          msg = '禁用'
        }
        this.$messageBox.confirm('确定' + msg + '该条节日价格配置吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.changeStatus(price_uuid, status)
        }).catch(res => {

        });
      },
      // 禁用启用发送请求
      async changeStatus(price_uuid, status) {
        let data = {
          price_uuid,
          status
        }
        let res = Http.updatePriceStatus(data)
        if (res.flag == 1) {
          this.$message.success(res.message);
        }
        await this.getData()
      },
      // 获取抽成详情
      async getHolidayPriceDetail(price_uuid) {
        let res = await Http.getHolidayPriceDetail({
          price_uuid: price_uuid,
        })
        if (res.flag == 1) {
          this.detailList = res.data
          this.comlist = res.data
        }
      },
      getbrokerageData(item) {
        console.log(item)
        this.dialogVisible2 = true
      },
      saveBrokerageData() {
        // this.brokerage_data.level=this.levelIdCache
        let obj = {}
        for (let key in this.brokerage_data) {
          obj.price_type = this.brokerage_data.price_type
          obj.level_id = this.levelIdCache
          if (key.indexOf(this.brokerage_data.price_type) != -1 || key == 'is_show' || key == 'is_upload_health_cert' || key == 'guarantee_price') {
            obj[key] = this.brokerage_data[key]
          } else if (key !== 'price_type') {
            this.brokerage_data[key] = ''
          }
        }
        if (this.brokerage_data.price_type == 'const_price_difference') {
          if (!this.brokerage_data.const_price_difference$normal_price) {
            this.$message.error('请输入雇主固定发布价格')
            return false;
          }
          if (!this.brokerage_data.const_price_difference$difference_price) {
            this.$message.error('请输入平台差额')
            return false;
          }
          // if (!this.brokerage_data.const_price_difference$normal_price_user) {
          //   this.$message.error('请输入工人固定收到普工价格')
          //   return false;
          // }
        }
        if (this.brokerage_data.price_type == 'const_price_brokerage') {
          if (!this.brokerage_data.const_price_brokerage$normal_price) {
            this.$message.error('请输入雇主固定发布价格')
            return false;
          }
          if (!this.brokerage_data.const_price_brokerage$brokerage) {
            this.$message.error('请输入抽成')
            return false;
          }
        }
        if (this.pgDialogForm.level_id.indexOf(this.levelIdCache) > -1) {
          let i = this.pgDialogForm.level_id.indexOf(this.levelIdCache)
          this.pgDialogForm.level_id.splice(i, 1, this.levelIdCache)
          this.pgDialogForm.brokerage_data.splice(i, 1, obj)
        } else {
          this.pgDialogForm.level_id.push(this.levelIdCache)
          this.pgDialogForm.brokerage_data.push(obj)
        }
        this.levelDetailShow = false
      },
      deleteBrokerageData() {
        this.levelDetailShow = false
        let index = this.pgDialogForm.level_id.indexOf(this.levelIdCache)
        if (index > 0) {
          this.pgDialogForm.level_id.splice(index, 1)
          this.pgDialogForm.brokerage_data.splice(index, 1)
        }
      },
      handleClose2() {
        this.dialogVisible2 = false
      },
      handleClose3() {
        this.dialogVisible3 = false
      },
      editPriceSet(row){
        console.log(row)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tag {
    font-size: 14px;
    padding: 5px 15px;
    border: 1px solid #cfcfcf;
    display: inline-block;
    line-height: 2;

    &.tag-current {
      background: #409EFF;
      border-color: #409EFF;
      color: #fff;
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-right: none;
    }

    &:nth-child(2) {
      border-right: none;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: none;
    }
  }

  .w300 {
    width: 300px;
  }
</style>
